import React from 'react';
import { Helmet } from 'react-helmet-async';
import ComingSoon from '../components/ComingSoon';
// import Navigation from '../components/Navigation';

const Home: React.FC = () => {
  return (
    <>
      <Helmet>
        <title>Bonus Stage Publishing</title>
        <meta name="description" content="Bonus Stage Publishing - New Experience Coming Soon" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bonusstagepublishing.com" />
        <meta property="og:title" content="Bonus Stage Publishing" />
        <meta property="og:description" content="New Experience Coming Soon" />
        <meta property="og:image" content="https://bonusstagepublishing.com/horizontal-full_white-nobg.png" />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://bonusstagepublishing.com" />
        <meta property="twitter:title" content="Bonus Stage Publishing" />
        <meta property="twitter:description" content="New Experience Coming Soon" />
        <meta property="twitter:image" content="https://bonusstagepublishing.com/horizontal-full_white-nobg.png" />
      </Helmet>
      
      {/* Navigation commented out as requested */}
      {/* <Navigation /> */}
      <ComingSoon />
    </>
  );
};

export default Home; 