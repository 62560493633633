import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Home from './pages/Home';
import DiscGolfMasters from './pages/DiscGolfMasters';

// Component to handle redirection
const RedirectHandler: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if we're on the non-hash URL
    if (window.location.pathname === '/discgolfmasters' && !window.location.hash) {
      window.location.href = '/#/discgolfmasters';
    }
  }, [location]);

  return null;
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <Router>
        <RedirectHandler />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/discgolfmasters" element={<DiscGolfMasters />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
};

export default App;