import React, { useState } from 'react';
import { useFormspark } from '@formspark/use-formspark';
import { Helmet } from 'react-helmet-async';
// import Navigation from '../components/Navigation';

const FORMSPARK_FORM_ID = "AUzHQBENo";

const DiscGolfMasters: React.FC = () => {
  const [email, setEmail] = useState<string>('');
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    await submit({ email });
    setSubmitted(true);
    setEmail('');
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <Helmet>
        <title>Disc Golf Masters - Bonus Stage Publishing</title>
        <meta name="description" content="Experience the thrill of professional disc golf in this authentic simulation. Perfect your throws, master multiple courses, and compete against players worldwide." />
        <link rel="canonical" href="https://bonusstage.co/#/discgolfmasters" />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://bonusstage.co/#/discgolfmasters" />
        <meta property="og:title" content="Disc Golf Masters - Bonus Stage Publishing" />
        <meta property="og:description" content="Experience the thrill of professional disc golf in this authentic simulation. Perfect your throws, master multiple courses, and compete against players worldwide." />
        <meta property="og:image" content="https://bonusstage.co/dgm-og_image.jpg" />
        <meta property="og:site_name" content="Bonus Stage Publishing" />
        
        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content="https://bonusstage.co/#/discgolfmasters" />
        <meta name="twitter:title" content="Disc Golf Masters - Bonus Stage Publishing" />
        <meta name="twitter:description" content="Experience the thrill of professional disc golf in this authentic simulation. Perfect your throws, master multiple courses, and compete against players worldwide." />
        <meta name="twitter:image" content="https://bonusstage.co/dgm-og_image.jpg" />
        
        {/* Additional meta tags for better SEO */}
        <meta name="keywords" content="disc golf, video game, simulation, sports game, Bonus Stage Publishing" />
        <meta name="author" content="Bonus Stage Publishing" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      
      {/* <Navigation /> */}
      
      {/* Hero Section with Video */}
      <section className="relative w-full overflow-hidden" style={{ paddingTop: 'calc(700 / 1440 * 100%)' }}>
        <div className="absolute inset-0 bg-black/10 z-10"></div>
        
        {/* Logo overlay - positioned on top of the video */}
        <div className="absolute inset-0 flex items-center justify-center z-20">
          <div className="w-[60%] sm:w-[60%] md:w-[60%] lg:w-[60%] max-w-4xl logo-animate">
            <img 
              src="/dgm-logo-large.png" 
              alt="Disc Golf Masters Logo" 
              className="w-full h-auto drop-shadow-2xl filter"
              style={{ filter: 'drop-shadow(0 0 10px rgba(0, 0, 0, 0.3))' }}
            />
          </div>
        </div>

        <div className="absolute inset-0 z-0 overflow-hidden bg-gradient-to-r from-green-900 to-blue-900">
          <div className="video-container">
            <video 
              autoPlay 
              loop 
              muted 
              playsInline
              className="responsive-video"
              poster="/discgolfmasters-poster.jpg"
              style={{ objectPosition: 'top center' }}
            >
              <source src="/discgolfmasters-hero.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Steam Widget Section */}
      <section className="py-8 md:py-12 bg-black border-t border-green-900/30">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">Wishlist on Steam</h2>
          <div className="flex justify-center">
            <div className="w-full overflow-hidden max-w-[646px]">
              <iframe 
                src="https://store.steampowered.com/widget/2819400/" 
                frameBorder="0" 
                width="646" 
                height="190"
                title="Disc Golf Masters on Steam"
                className="max-w-full"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* YouTube Video Section */}
      <section className="py-16 bg-black border-t border-green-900/20">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl md:text-3xl font-bold mb-8 text-center">Watch the Teaser Trailer</h2>
          <div className="flex justify-center">
            <div className="aspect-video w-full max-w-4xl shadow-2xl shadow-green-900/20">
              <iframe 
                width="560" 
                height="315" 
                src="https://www.youtube.com/embed/YQXeVE5-hlo?si=S-BHxy-LI2z3iUpP" 
                title="YouTube video player" 
                frameBorder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                referrerPolicy="strict-origin-when-cross-origin" 
                allowFullScreen
                className="w-full h-full rounded"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {/* Newsletter Section */}
      <section className="py-16 md:py-20 bg-gradient-to-b from-black via-green-900/40 to-black border-t border-green-900/20">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-6">Stay In The Loop</h2>
            <p className="text-xl mb-10">Sign up to our newsletter to receive updates about Disc Golf Masters and be the first to know about playtests, demos, and upcoming release!</p>
            
            {submitted ? (
              <div className="bg-green-700/30 border border-green-600 rounded-lg p-6">
                <p className="text-xl">Thanks for signing up! We'll keep you updated.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className="flex flex-col md:flex-row gap-3 justify-center">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email address"
                  required
                  className="px-4 py-3 rounded-lg bg-black/50 border border-gray-700 focus:outline-none focus:ring-2 focus:ring-green-600 w-full md:w-auto md:flex-1"
                />
                <button 
                  type="submit" 
                  disabled={submitting}
                  className="bg-green-600 hover:bg-green-700 disabled:bg-gray-600 text-white px-6 py-3 rounded-lg font-bold transition-colors"
                >
                  {submitting ? 'Submitting...' : 'Sign Up'}
                </button>
              </form>
            )}
          </div>
        </div>
      </section>

      {/* Additional Content Section */}
      <section className="py-16 md:py-20 bg-black/90 border-t border-green-900/20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-6">About Disc Golf Masters</h2>
          <div className="max-w-4xl mx-auto">
            <p className="text-xl mb-6">Experience the thrill of professional disc golf in this authentic simulation. Perfect your throws, master multiple courses, and compete against players worldwide.</p>
            <p className="text-lg text-gray-400">More features, screenshots, and gameplay details coming soon!</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DiscGolfMasters; 